'use client'

import { useContext, useEffect } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import { getCookie, setCookie } from 'cookies-next'
import ModalGeoip from '../Modal/ModalGeoip'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'

type geoipResult = {
  country_code?: string
  country_name?: string
}

const getGeoip = () => {
  const geoipKey = process.env.NEXT_PUBLIC_GEOIP_API_KEY

  const locale = useCurrentLocale()
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const checkFromCookie = getCookie('geoipCheck')

  // return user IP
  async function toJson(url: string) {
    const res = await fetch(url)
    return await res.json()
  }

  useEffect(() => {
    if (!checkFromCookie && geoipKey) {
      toJson(`https://api.ipdata.co?api-key=${geoipKey}`).then((data: geoipResult) => {
        // if user IP is different from current locale
        const countryCode = data.country_code?.toLowerCase()
        if (data && countryCode && locale !== countryCode) {
          const uuid = crypto.randomUUID()

          const handleClose = () => {
            setCookie('geoipCheck', true)
            removeModal(uuid)
            document.body.classList.remove('no-overflow')
          }

          addModal({
            uuid,
            component: (
              <ModalGeoip
                uuid={uuid}
                onClick={handleClose}
                countryCode={countryCode}
                countryName={data.country_name}
                onClose={handleClose}
              />
            ),
          })
        }
      })
    }
  }, [])

  return <></>
}

export default getGeoip
