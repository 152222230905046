'use client'

import { FunctionComponent, useEffect, useState } from 'react'
import Zendesk, { ZendeskAPI } from './ZendexConfig'

const ZENDESK_KEY = '0cf06b76-1da2-466a-a7d7-25c72a90dcfe'

export const ZendeskWidget: FunctionComponent = () => {
  const [showWidget, setShowWidget] = useState(false)

  useEffect(() => {
    ZendeskAPI('messenger', 'open')
    setShowWidget(true)
    return () => {
      ZendeskAPI('messenger', 'hide')
      setShowWidget(false)
      const iframeElement = document.getElementById('launcher')
      if (iframeElement) {
        iframeElement.style.display = 'none'
      }
    }
  }, [])

  return showWidget && <Zendesk defer zendeskKey={ZENDESK_KEY} />
}
