'use client'

import { FunctionComponent, ReactNode, useContext } from 'react'
import { ContrastContext } from '../../context/ContrastContext'

type PageRenderProps = {
  className?: string
  children: ReactNode
}
export const PageRender: FunctionComponent<PageRenderProps> = ({ className, children }) => {
  const { contrast } = useContext(ContrastContext)

  return <div className={`${className ?? ''} ${contrast && 'main-contrast'}`}>{children}</div>
}
