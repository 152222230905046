import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'

export const initSliderForListSelection = (
  parentClassName?: string,
  centerInsufficientSlides?: boolean,
) => {
  const prefixClassNameFromParent = parentClassName ? `.${parentClassName}` : ''

  const swiper = new Swiper(
    `${prefixClassNameFromParent} .list-selection .swiper.list-selection-swiper`,
    {
      watchSlidesProgress: true,
      modules: [Pagination, Navigation],
      pagination: {
        clickable: false,
        el: `${prefixClassNameFromParent} .list-selection .list-selection-pagination`,
        bulletClass: 'custom-slider-pagination-bullet',
        bulletActiveClass: 'custom-slider-pagination-bullet-active',
      },
      navigation: {
        prevEl: `${prefixClassNameFromParent} .list-selection .custom-arrow-navigation-left`,
        nextEl: `${prefixClassNameFromParent} .list-selection .custom-arrow-navigation-right`,
        disabledClass: 'custom-arrow-navigation-disabled',
      },
      breakpoints: {
        320: {
          spaceBetween: 8,
          slidesPerView: 2,
          allowTouchMove: true,
        },
        960: {
          spaceBetween: 16,
          slidesPerView: 4,
          allowTouchMove: false,
          centerInsufficientSlides,
        },
      },
      observer: true,
    },
  )

  return swiper
}

export const initArrowPositionForListSelection = () => {
  const firstImgEl = document.querySelector('.selection-item .push img')
  firstImgEl?.addEventListener('load', () => {
    const { height } = firstImgEl.getBoundingClientRect()
    const newHeight = height / 2
    document
      .querySelectorAll<HTMLElement>('.list-selection .custom-arrow-navigation')
      .forEach((el) => {
        el.style.top = `${newHeight}px`
      })
  })
}
