'use client'

import { FunctionComponent, useContext, useEffect } from 'react'
import { gtmPush } from '../../utils/gtmHelpers'
import { ContrastContext } from '../../context/ContrastContext'
import { GTMPageEvent } from '../../../domain/gtm'
import { UserContext } from '../../context/UserContext'
import { getCookie } from 'cookies-next'

type GtmProps = {
  type: string
  clientStatut?: string
}
const GtmPush: FunctionComponent<GtmProps> = ({ type }) => {
  const { contrast } = useContext(ContrastContext)
  const { user } = useContext(UserContext)
  const userToken = getCookie('userToken')

  useEffect(() => {
    if (userToken) {
      gtmPush({
        page_type: type,
        accessibility: contrast ? 'accessible' : 'standard',
        user_id: getCookie('customerIdEncrypted') ?? '',
        user_status: getCookie('userToken') ? 'logged_in' : 'logged_out',
        client_status: user?.has_orders ? 'ancien client' : 'nouveau client',
        cid_hash: getCookie('userEmailEncrypted') ?? '',
      } as GTMPageEvent)
    } else {
      gtmPush({
        page_type: type,
        accessibility: contrast ? 'accessible' : 'standard',
        user_id: '',
        user_status: 'logged_out',
        client_status: '',
        cid_hash: '',
      } as GTMPageEvent)
    }
  }, [contrast])

  return null
}

export default GtmPush
