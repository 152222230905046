import { Component } from 'react'
import PropTypes from 'prop-types'

declare global {
  /* eslint-disable no-unused-vars */
  interface Window {
    zE: any
    onLoaded?: () => void
    zESettings?: any
  }
}

interface ZendeskProps {
  onLoaded?: () => void
  defer?: boolean
  zendeskKey: string
  [key: string]: any
}

const canUseDOM = () => {
  if (typeof window === 'undefined' || !window.document || !window.document.createElement) {
    return false
  }
  return true
}

export const ZendeskAPI = (...args) => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(args)
  } else {
    /* eslint-disable no-console */
    console.warn('Zendesk is not initialized yet')
  }
}

class Zendesk extends Component<ZendeskProps> {
  static propTypes: {
    zendeskKey: PropTypes.Validator<string>
    defer: PropTypes.Requireable<boolean>
  }
  constructor(props) {
    super(props)
    this.insertScript = this.insertScript.bind(this)
    this.onScriptLoaded = this.onScriptLoaded.bind(this)
  }

  onScriptLoaded() {
    if (typeof this.props.onLoaded === 'function') {
      this.props.onLoaded()
    }
  }

  insertScript(zendeskKey, defer) {
    const script = document.createElement('script')
    if (defer) {
      script.defer = true
    } else {
      script.async = true
    }
    script.id = 'ze-snippet'
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`
    script.addEventListener('load', this.onScriptLoaded)
    document.body.appendChild(script)
  }

  componentDidMount(): void {
    if (canUseDOM() && !window.zE) {
      const { defer, zendeskKey, ...other } = this.props
      this.insertScript(zendeskKey, defer)
      window.zESettings = other
    }
  }

  componentWillUnmount(): void {
    if (!canUseDOM || !window.zE) {
      return
    }
    delete window.zE
    delete window.zESettings
    const script = document.getElementById('ze-snippet')
    if (script) {
      document.body.removeChild(script)
    }
  }

  render() {
    return null
  }
}

Zendesk.propTypes = {
  zendeskKey: PropTypes.string.isRequired,
  defer: PropTypes.bool,
}

export default Zendesk
