'use client'

import { motion } from 'framer-motion'
import { ReactElement, useEffect, useState } from 'react'
import { SelectionItem } from '../../../domain/selectionItem'
import { useIsomorphicLayoutEffect } from '../../../core/hook/useIsomorphicLayout'
import {
  initArrowPositionForListSelection,
  initSliderForListSelection,
} from '../../utils/ListSelection.utils'
import ListSelectionItem from '../ListSelectionItem/ListSelectionItem'
import { ArrowNavigationLeft, ArrowNavigationRight } from '../SliderUtils/ArrowNavigation'
import { useIsMobile } from '../../utils/screen.utils'
import { handleError } from '../../../core/hook/utils'
import { useQuery } from '../../../core/hook/useQuery'
import { ProductClientDocument } from '../../../queries/ProductClient.gql'

type ListSelectionProps = {
  items: SelectionItem[]
  name?: ReactElement<'h2' | 'h3' | 'h4' | 'h5' | 'h6'>
  parentClassName?: string
  centerInsufficientSlides?: boolean
  hideColorVariations?: boolean
  disableSwiperOfImages?: boolean
  isHome?: boolean
  listId?: string
  listName?: string
}

const ListSelection = ({
  items,
  name,
  parentClassName,
  centerInsufficientSlides,
  hideColorVariations = false,
  disableSwiperOfImages = false,
  isHome = false,
  listId = '',
  listName = '',
}: ListSelectionProps) => {
  const isMobile = useIsMobile()

  const { data } = useQuery(ProductClientDocument, {
    variables: {
      filters: { url_key: { in: items.map(
        (item) => item.urlKey
          ) } },
      pageSize: items.length,
    },
    fetchPolicy: "no-cache"
  })
  const [itemsData, setItemsData] = useState<SelectionItem[]>(items)

  useEffect(() => {
     if (!data?.products?.items) {
       setItemsData(itemsData.map((item) => ({
            ...item,
            stockStatus: "Loading",
          })))
     } else {
        setItemsData(
          itemsData.map((item) => {
            const product = data.products?.items!.find(
              (product) => product!.id === item.id
            )

            return {
              ...item,
              stockStatus: product?.stock_status ?? item?.stockStatus,
            }
          })
        )
     }
  }, [data])

  useIsomorphicLayoutEffect(() => {
    const swiper = initSliderForListSelection(parentClassName, centerInsufficientSlides)

    initArrowPositionForListSelection()

    return () => {
      try {
        swiper.destroy?.()
      } catch (error) {
        // TODO workaround
        handleError(error, false)
      }
    }
  }, [parentClassName, centerInsufficientSlides])

  if (itemsData.length < 1) {
    return null
  }

  return (
    <section className='list-selection'>
      {name}
      <motion.div
        style={{ y: 150, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
        className='list-selection-wrapper'>
        <div className='swiper list-selection-swiper'>
          <div className='swiper-wrapper list-selection-swiper-wrapper'>
            {itemsData.map((item, i) => (
              <div className='swiper-slide list-selection-swiper-slide' key={item.urlKey}>
                <ListSelectionItem
                  item={item}
                  isHome={isHome}
                  listId={listId}
                  listName={listName}
                  className='list-selection'
                  hideColorVariations={hideColorVariations}
                  keepOnlyOneImage={disableSwiperOfImages}
                  position={i + 1}
                />
              </div>
            ))}
          </div>
        </div>

        {(itemsData.length > 4 || (isMobile && itemsData.length > 2)) && (
          <div className='navigation-container'>
            <div className='bloc-navigation bloc-navigation-left'>
              <ArrowNavigationLeft />
            </div>

            <div className='explorer-animation bullet'>
              <div className='explorer-animation-bullet' />
              <div className='explorer-animation-bullet' />
              <div className='explorer-animation-bullet' />
            </div>

            <div className='bloc-navigation bloc-navigation-right'>
              <ArrowNavigationRight />
            </div>
          </div>
        )}
      </motion.div>
    </section>
  )
}

export default ListSelection
