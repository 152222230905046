'use client'

import { FunctionComponent, useState } from 'react'
import { Modal } from './Modal'
import { Button } from '../Button/Button'
import { Configurator } from '../Footer/Configurator'
import { useTranslation } from '../../context/ConfigContext'
import { useQuery } from '@tanstack/react-query'
import findTranslation from '../../../findTranslation'
import Loading from '../Loading/Loading'

interface GeoipModalProps extends ModalInterface {
  countryName?: string
  countryCode: string
  onClick: () => void
}

const fetchTranslationsByLocale = async (locale: string) => {
  const response = await fetch(`/api/translations?locale=${locale}`)
  const data = await response.json()

  if (!data.value) {
    const errorMessage = `empty dictionary for locale: ${locale}`
    // eslint-disable-next-line no-console
    console.error(errorMessage)
    throw new Error(errorMessage)
  }

  return findTranslation(data.value)
}

const ModalGeoip: FunctionComponent<GeoipModalProps> = ({
  uuid,
  onClose,
  onClick,
  countryCode,
  countryName,
}) => {
  const [displayOthers, setOthers] = useState(false)
  const tWithCurrentLocale = useTranslation()
  const { data: tWithCountryCode, isLoading } = useQuery(
    ['translations', countryCode],
    () => fetchTranslationsByLocale(countryCode),
    {
      enabled: !!countryCode,
    },
  )

  const t = tWithCountryCode ?? tWithCurrentLocale

  return (
    <Modal uuid={uuid} dark={false} center anchor={'center'} showLogo={false} onClose={onClose}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='modal-geoip'>
          {displayOthers ? (
            <div className='modal-geoip-others'>
              <p>{t('geoip_popup_redirect_disclaimer', {}, true)}</p>
              <Configurator displayShipping={true} />
            </div>
          ) : (
            <>
              <h2 className='title-h5'>{t('geoip_popup_title', {}, true)}</h2>
              <hr />
              <p>
                {t('geoip_popup_text', {}, true)} <span>{countryName}</span>
              </p>
              <div className='modal-geoip-links'>
                <a href={`/${countryCode}`} className='button black'>
                  {t('geoip_popup_accept_button', {}, true)}
                </a>
                <Button
                  primary={true}
                  classname='button black'
                  size={'large'}
                  style='dark'
                  onClick={onClick}
                  label={t('geoip_popup_decline_button', {}, false)}
                />
                <button
                  type='button'
                  className='set-others button border-black'
                  onClick={() => setOthers(true)}>
                  {t('geoip_popup_other_countries_button', {}, true)}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  )
}

export default ModalGeoip
